import React, { useEffect, useState, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import classnames from 'classnames';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';

import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BarChartIcon from '@material-ui/icons/BarChart';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import MenuIcon from '@material-ui/icons/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import TranslateIcon from '@material-ui/icons/Translate';
import ViewListIcon from '@material-ui/icons/ViewList';
import TableChartIcon from '@material-ui/icons/TableChart';

import TabList from '../../components/TabList';

import BaseForm from './components/BaseForm';
import Classes from './components/Classes';
import GenericClasses from './components/GenericClasses';
import GeospatialObjects from './components/GeospatialObjects';
import TransitionBaseForm from './components/TransitionBaseForm';
import TransitionClasses from './components/TransitionClasses';
import QualityBaseForm from './components/QualityBaseForm';
import QualityClasses from './components/QualityClasses';
import PastureQualityCoverageClasses from './components/PastureQualityCoverageClasses';
import TranslateOptionsBox from './components/TranslateOptionsBox';
import InstitutionalMenu from './components/InstitutionalMenu';
import AccountMenu from './components/AccountMenu';

import {
  GET_BASE_DATA,
  GET_MODULE_DATA
} from './query';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useWindowSize from '../../../../hooks/useWindowSize';
import usePrevious from '../../../../hooks/usePrevious';

import logo from './chile-es-horizontal.png';
import logoFire from './logo-mapbiomas-fogo.png';
import logoSentinelPT from './10-metros-pt-br-horizontal.svg';
import logoSentinelEN from './10-metros-en-horizontal.svg';

import initialBaseParams from '../../../../data/initialBaseParams';
import mapActiveModuleToDefaultParams from '../../../../data/mapActiveModuleToDefaultParams';
import customClientModules from '../../../../data/customClientModules';

import customClient from '../../../../core/custom-apollo-client';

import ModuleIconComponent from './ModuleIconComponent';

import styles from './Header.module.scss';

const NEW_MODULE_KEYS = [];

const BETA_MODULE_KEYS = [
  'sentinel',
  'soil',
];

function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

// TODO: Remove these mappers when the saved user maps are updated with the new keys

function mapNewModuleListKeyToOldKey(key) {
  return {
    coverage: 'coverage',
    quality_of_pasture_data: 'quality_of_pasture_data',
  }[key] || key;
}

function mapOldModuleListKeyToNewKey(key) {
  return {
    landUseAndCover: 'coverage',
    pastureQuality: 'quality_of_pasture_data',
  }[key] || key;
}

function getModuleItemClassName(key) {
  const parsedKey = mapNewModuleListKeyToOldKey(key);

  const mapModuleKeyToClassName = {
    'coverage': 'menuBarListItemCoverage',
    'coverage_quality': 'menuBarListItemCoverageQuality',
    'temporal_analysis': 'menuBarListItemTrajectories',
    'quality_of_pasture_data': 'menuBarListItemQualityOfPastureData',
    'deforestation': 'menuBarListItemDeforestation',
    'regeneration': 'menuBarListItemRegeneration',
    'infrastructure': 'menuBarListItemInfrastructure',
    'irrigation': 'menuBarListItemIrrigation',
    'fire': 'menuBarListItemFire',
    'scenarios': 'menuBarListItem',
    'mining': 'menuBarListItemMining',
    'sentinel': 'menuBarListItemSentinel',
    'soil': 'menuBarListItemSoil',
  };

  return mapModuleKeyToClassName[parsedKey];
}

const mapLocaleKeyToAbbreviation = {
  'pt-BR': 'PT',
  'en': 'EN',
  'es': 'ES'
};

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '8px 12px',
    fontSize: 14,
    fontWeight: 400,
    color: 'white',
    backgroundColor: '#656565',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
  },
  arrow: {
    color: '#656565'
  },
}))(Tooltip);

const TOP_PADDING = 80;
const TOP_PADDING_LARGE = 176;
const BOTTOM_PADDING = 93;

function Header({
  history,
  height,
  iframeMode,
}) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const locale = localStorage.getItem('locale') || 'es';
  const formatMessage = useFormatMessage();
  const windowSize = useWindowSize();
  const client = useApolloClient();
  const [navIsVisible, setNavIsVisible] = useState(false);
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [accountMenuIsVisible, setAccountMenuIsVisible] = useState(false);
  const [translateBoxAnchorEl, setTranslateBoxAnchorEl] = useState(null);
  const [showFireModeContent, setShowFireModeContent] = useState(null);
  const [showInfrastructureModeContent, setShowInfrastructureModeContent] = useState(null);
  const [showIrrigationModeContent, setShowIrrigationModeContent] = useState(null);
  const [showDeforestationModeContent, setShowDeforestationModeContent] = useState(null);
  const { data: baseData } = useQuery(GET_BASE_DATA);
  const { data: modulesData } = useQuery(GET_MODULE_DATA);
  const { data: customModulesData } = useQuery(GET_MODULE_DATA, {
    client: customClient
  });
  const activeModule = _.get(baseData, 'app.activeModule');
  const prevActiveModule = usePrevious(activeModule);
  const activeModuleContent = _.get(baseData, 'app.activeModuleContent');
  const headerIsVisible = _.get(baseData, 'app.headerIsVisible');
  const buffer = _.get(baseData, 'app.baseParams.buffer');
  const activeClassTreeOptionValue = _.get(baseData, 'app.baseParams.activeClassTreeOptionValue');
  const isWaterIframe = activeModuleContent === 'water:water_iframe';
  const isFireIframe = activeModuleContent === 'fire:fire_iframe';
  const isInfrastructureIframe = activeModuleContent === 'infrastructure:infrastructure_iframe';
  const isIrrigationIframe = activeModuleContent === 'irrigation:irrigation_iframe';

  const modulesList = _.get(modulesData, 'modulesList');
  const customModulesList = _.get(customModulesData, 'modulesList');
  const activeModuleData = _.find(modulesList, { key: mapOldModuleListKeyToNewKey(activeModule) });
  const submodulesList = _.get(activeModuleData, 'submodules');

  const updateClientAppState = (params) => {
    client.writeData({
      data: {
        app: {
          __typename: 'AppState',
          ...params
        }
      }
    });
  };

  const updateClientAppBaseParamsState = (params) => {
    updateClientAppState({
      baseParams: {
        __typename: 'AppBaseParamsState',
        ...params
      }
    });
  };

  const handleActiveModuleChange = (key) => {
    if (iframeMode) {
      history.push('/');
    }

    const defaultParams = mapActiveModuleToDefaultParams[key] || {};
    const baseParams = _.get(defaultParams, 'baseParams');

    updateClientAppState({
      activeModule: key,
      ...(baseParams ? _.omit(defaultParams, 'baseParams') : defaultParams)
    });

    if (baseParams) {
      updateClientAppBaseParamsState(baseParams);
    }

    if (_.includes(customClientModules, prevActiveModule) || _.includes(customClientModules, key)) {
      updateClientAppBaseParamsState(initialBaseParams);
    }

    setShowFireModeContent(false);
    setShowInfrastructureModeContent(false);
    setShowIrrigationModeContent(false);
    toggleNav();
  };

  const toggleVisibility = () => {
    updateClientAppState({ headerIsVisible: !headerIsVisible });
  };

  const toggleNav = () => setNavIsVisible(!navIsVisible);

  const toggleMenu = () => setMenuIsVisible(!menuIsVisible);

  const toggleAccountMenu = () => {
    const isOpen = !!accountMenuIsVisible;

    if (isOpen && (accountMenuIsVisible === 'myMaps' || accountMenuIsVisible === 'newMap')) {
      setAccountMenuIsVisible('default');
    } else if (isOpen) {
      setAccountMenuIsVisible(null);
    } else {
      setAccountMenuIsVisible('default');
    }
  };

  const closeAccountMenu = () => {
    setAccountMenuIsVisible(null);
  };

  const handleShowClassInfo = (id) => {
    if (isMobile) {
      updateClientAppState({
        headerIsVisible: false,
        showClassInfo: id
      });
    } else {
      updateClientAppState({
        showClassInfo: id
      });
    }
  };

  const handleShowDashboardInfo = () => {
    if (!isMobile) {
      updateClientAppState({
        showDashboardInfo: true,
      });
    } else {
      updateClientAppState({
        showDashboardInfo: true,
        headerIsVisible: false,
        mapPointInfo: null,
        showClassInfo: null,
      });
    }
  };

  const handleTabChange = (newValue) => {
    let appParams = {
      activeModuleContent: newValue,
      showBeforeAndAfterMosaic: false,
    };

    updateClientAppState(appParams);
  };

  const handleMapFlyToChange = (boundingBox) => {
    updateClientAppState({ mapFlyTo: boundingBox });
  };

  const handleAddNewMap = () => {
    setAccountMenuIsVisible('newMap');
  };

  const handleShowMyMaps = () => {
    setAccountMenuIsVisible('myMaps');
  };

  const renderTabContent = () => {
    const classesViewMode = _.get(baseData, 'app.baseParams.classesViewMode');

    switch(activeModuleContent) {
      case 'coverage:coverage_main': {
        return (
          <Fragment>
            <BaseForm
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              moduleId={ _.get(_.find(modulesList, { key: 'coverage' }), 'id') }
              submoduleKey={ 'coverage_main' }
              hideLegendIndex={ activeClassTreeOptionValue === 'natural_or_anthropic' }
              hideLevelsControl={ activeClassTreeOptionValue === 'natural_or_anthropic' }
            />
          </Fragment>
        );
      }
      case 'coverage:coverage_changes': {
        return (
          <Fragment>
            <BaseForm
              isTransition
              onFlyTo={ handleMapFlyToChange }
            />
            <TransitionClasses
              onItemsChange={ updateClientAppBaseParamsState }
            />
          </Fragment>
        );
      }
      case 'coverage:coverage_quality': {
        return (
          <Fragment>
            <BaseForm
              onFlyTo={ handleMapFlyToChange }
            />
            <QualityClasses />
          </Fragment>
        );
      }
      case 'temporal_analysis:temporal_analysis_stable_areas': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              moduleId={ _.get(_.find(modulesList, { key: 'temporal_analysis' }), 'id') }
              submoduleKey={ 'temporal_analysis_stable_areas' }
            />
          </Fragment>
        );
      }
      case 'temporal_analysis:temporal_analysis_number_of_classes': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              moduleId={ _.get(_.find(modulesList, { key: 'temporal_analysis' }), 'id') }
              submoduleKey={ 'temporal_analysis_number_of_classes' }
            />
          </Fragment>
        );
      }
      case 'temporal_analysis:temporal_analysis_number_of_changes': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              moduleId={ _.get(_.find(modulesList, { key: 'temporal_analysis' }), 'id') }
              submoduleKey={ 'temporal_analysis_number_of_changes' }
            />
          </Fragment>
        );
      }
      case 'coverage_quality:coverage_quality_main': {
        return (
          <Fragment>
            <BaseForm
              onFlyTo={ handleMapFlyToChange }
            />
            <QualityClasses />
          </Fragment>
        );
      }
      case 'quality_of_pasture_data:quality_of_pasture_data_main': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              key="quality_of_pasture_data:quality_of_pasture_data_main"
              moduleId={ _.get(_.find(modulesList, { key: 'quality_of_pasture_data' }), 'id') }
              submoduleKey={ 'quality_of_pasture_data_main' }
            />
          </Fragment>
        );
      }
      case 'quality_of_pasture_data:quality_of_pasture_data_changes': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              key="quality_of_pasture_data:quality_of_pasture_data_changes"
              moduleId={ _.get(_.find(modulesList, { key: 'quality_of_pasture_data' }), 'id') }
              submoduleKey={ 'quality_of_pasture_data_changes' }
            />
          </Fragment>
        );
      }
      case 'irrigation:irrigation_main': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              moduleId={ _.get(_.find(modulesList, { key: 'irrigation' }), 'id') }
              submoduleKey={ 'irrigation_main' }
            />
          </Fragment>
        );
      }
      case 'deforestation:deforestation_annual': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              moduleId={ _.get(_.find(modulesList, { key: 'deforestation' }), 'id') }
              submoduleKey={ 'deforestation_annual' }
            />
          </Fragment>
        );
      }
      case 'deforestation:deforestation_accumulated': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              moduleId={ _.get(_.find(modulesList, { key: 'deforestation' }), 'id') }
              submoduleKey={ 'deforestation_accumulated' }
            />
          </Fragment>
        );
      }
      case 'deforestation:deforestation_frequency': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              hideLegendIndex
              moduleId={ _.get(_.find(modulesList, { key: 'deforestation' }), 'id') }
              submoduleKey={ 'deforestation_frequency' }
            />
          </Fragment>
        );
      }
      case 'regeneration:regeneration_annual': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              moduleId={ _.get(_.find(modulesList, { key: 'regeneration' }), 'id') }
              submoduleKey={ 'regeneration_annual' }
            />
          </Fragment>
        );
      }
      case 'regeneration:regeneration_accumulated': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              moduleId={ _.get(_.find(modulesList, { key: 'regeneration' }), 'id') }
              submoduleKey={ 'regeneration_accumulated' }
            />
          </Fragment>
        );
      }
      case 'regeneration:regeneration_age': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              hideLegendIndex
              moduleId={ _.get(_.find(modulesList, { key: 'regeneration' }), 'id') }
              submoduleKey={ 'regeneration_age' }
            />
          </Fragment>
        );
      }
      case 'infrastructure:infrastructure_main': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              showBufferField
              territoryCategoriesGroup="infrastructure"
              onFlyTo={ handleMapFlyToChange }
            />
            <GeospatialObjects
              objectTree={_.get(_.find(modulesList, { key: 'infrastructure' }), 'submodules[0].geospatialObjectCategoryTree') }
            />
            <GenericClasses
              moduleId={ _.get(_.find(modulesList, { key: 'infrastructure' }), 'id') }
              submoduleKey={ 'infrastructure_main' }
            />
          </Fragment>
        );
      }
      case 'fire:fire_annual': {
        return (
          <Fragment>
            <BaseForm
              clientType="custom"
              territoryCategoriesGroup="fire"
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              clientType="custom"
              moduleId={ _.get(_.find(customModulesList, { key: 'fire' }), 'id') }
              submoduleKey={ 'fire_annual' }
            />
          </Fragment>
        );
      }
      case 'fire:fire_accumulated': {
        return (
          <Fragment>
            <BaseForm
              clientType="custom"
              territoryCategoriesGroup="fire"
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              clientType="custom"
              moduleId={ _.get(_.find(customModulesList, { key: 'fire' }), 'id') }
              submoduleKey={ 'fire_accumulated' }
            />
          </Fragment>
        );
      }
      case 'fire:fire_frequency': {
        return (
          <Fragment>
            <BaseForm
              clientType="custom"
              territoryCategoriesGroup="fire"
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              clientType="custom"
              hideLegendIndex
              moduleId={ _.get(_.find(customModulesList, { key: 'fire' }), 'id') }
              submoduleKey={ 'fire_frequency' }
            />
          </Fragment>
        );
      }
      case 'fire:fire_monthly': {
        return (
          <Fragment>
            <BaseForm
              clientType="custom"
              territoryCategoriesGroup="fire"
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              clientType="custom"
              moduleId={ _.get(_.find(customModulesList, { key: 'fire' }), 'id') }
              submoduleKey={ 'fire_monthly' }
            />
          </Fragment>
        );
      }
      case 'mining:mining_main': {
        return (
          <Fragment>
            <BaseForm
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              moduleId={ _.get(_.find(modulesList, { key: 'mining' }), 'id') }
              submoduleKey={ 'mining_main' }
            />
          </Fragment>
        );
      }
      case 'soil:soil_main': {
        return (
          <Fragment>
            <BaseForm
              hideGroupType
              clientType="custom"
              onFlyTo={ handleMapFlyToChange }
              territoryCategoriesGroup="soil"
              showMultipleTerritories={ false }
            />
            <GenericClasses
              clientType="custom"
              moduleId={ _.get(_.find(customModulesList, { key: 'soil' }), 'id') }
              submoduleKey={ 'soil_main' }
            />
          </Fragment>
        );
      }
      case 'sentinel:sentinel_coverage': {
        return (
          <Fragment>
            <div className={ styles.fireModeContentBoxExtra }>
              <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.warning.sentinel_coverage.extra_note.title" /></h2>
              <div
                className={ styles.fireModeContentBoxTextWrapper }
                dangerouslySetInnerHTML={{
                  __html: formatMessage('mapbiomas.warning.sentinel_coverage.extra_note.content')
                }}
              />
            </div>
            <BaseForm
              clientType="custom"
              territoryCategoriesGroup="sentinel"
              onFlyTo={ handleMapFlyToChange }
            />
            <GenericClasses
              clientType="custom"
              moduleId={ _.get(_.find(customModulesList, { key: 'sentinel' }), 'id') }
              submoduleKey={ 'sentinel_coverage' }
            />
          </Fragment>
        );
      }
      default: {
        return null;
      }
    }
  };

  const handleWaterClick = () => {
    history.push('/agua');
    updateClientAppState({ activeModuleContent: 'water:water_iframe' });
  };

  const hasStatisticsButton = activeModuleContent === 'coverage:coverage_changes';

  const parsedTabList = _.map(_.sortBy(submodulesList, 'displayOrder'), (item) => {

    const intl = useIntl()

    const mapSubmoduleIdToTooltip = {
      'fire_annual': intl.formatMessage({id: 'mapbiomas.statistics.burned.annual_total.module.tooltip'}),
      'fire_monthly': intl.formatMessage({id: 'mapbiomas.statistics.burned.monthly.module.tooltip'}),
      'fire_frequency': intl.formatMessage({id: 'mapbiomas.statistics.burned.frequency.module.tooltip'}),
      'fire_accumulated': intl.formatMessage({id: 'mapbiomas.statistics.burned.accumulated.module.tooltip'}),
    };

    const stringList = _.get(item, 'i18nStrings');
    const selectedString = _.find(stringList, { language: locale });
    const label = _.get(selectedString, 'stringValue');
    let tooltip = mapSubmoduleIdToTooltip[_.get(item, 'key')] || null;

    return {
      id: `${ _.get(activeModuleData, 'key') }:${ _.get(item, 'key') }`,
      label,
      elementId: _.get(item, 'key'),
      tooltip,
    };
  });

  const totalTabList = _.size(parsedTabList);
  const contentHeight = windowSize.height -
    (totalTabList > 2 ? TOP_PADDING_LARGE : TOP_PADDING) -
    (totalTabList === 2 ? 62 : 0) -
    BOTTOM_PADDING;

  const handleTranslateBoxOpen = (event) => {
    setTranslateBoxAnchorEl(translateBoxAnchorEl ? null : event.currentTarget);
  };

  const handleCustomModeClick = (module) => {
    if (module === 'fire') {
      setShowInfrastructureModeContent(false);
      setShowIrrigationModeContent(false);
      setShowDeforestationModeContent(false);
      setShowFireModeContent(true);
    } else if (module === 'infrastructure') {
      setShowFireModeContent(false);
      setShowIrrigationModeContent(false);
      setShowDeforestationModeContent(false);
      setShowInfrastructureModeContent(true);
    } else if (module === 'irrigation') {
      setShowFireModeContent(false);
      setShowInfrastructureModeContent(false);
      setShowDeforestationModeContent(false);
      setShowIrrigationModeContent(true);
    } else if (module === 'deforestation') {
      setShowFireModeContent(false);
      setShowInfrastructureModeContent(false);
      setShowIrrigationModeContent(false);
      setShowDeforestationModeContent(true);
    }
  };

  const showFireIframeMode = () => {
    history.push('/monitor-do-fogo');
    updateClientAppState({ activeModuleContent: 'fire:fire_iframe' });
  };

  const showInfrastructureIframeMode = () => {
    history.push('/pistas-de-pouso');
    updateClientAppState({ activeModuleContent: 'infrastructure:infrastructure_iframe' });
  };

  const showIrrigationIframeMode = () => {
    history.push('/dinamica-dos-pivos');
    updateClientAppState({ activeModuleContent: 'irrigation:irrigation_iframe' });
  };

  const renderMenuContent = () => {
    return (
      <div className={ styles.menuContent }>
        { _.size(parsedTabList) > 1 &&
          <div id="module-main-options" className={ styles.topContent }>
            <TabList
              fullWidth
              hasCustomItemSize
              size="small"
              items={ parsedTabList }
              value={ activeModuleContent }
              onChange={ handleTabChange }
            />
          </div>
        }
        <div className={ styles.contentWrapper } style={{ height: contentHeight }}>
          <div className={ styles.tabContent }>
            { renderTabContent() }
            { hasStatisticsButton &&
              <div className={ styles.statisticsButtonWrapper }>
                <Button
                  id="statistics-btn"
                  disabled={ activeModuleContent === 'infrastructure:infrastructure_main' && buffer === 0 }
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={ <BarChartIcon /> }
                  onClick={ handleShowDashboardInfo }
                >
                  <FormattedMessage id="mapbiomas.header.buttons.statistics" />
                </Button>
              </div>
            }
          </div>
        </div>
        <div className={ styles.bottomContent }>
          <Button
            variant="contained"
            size="small"
            className={ styles.actionButton }
            onClick={ handleAddNewMap }
          >
            <BookmarkIcon />
            <span><FormattedMessage id="mapbiomas.header.buttons.save_map" /></span>
          </Button>
          <Button
            variant="contained"
            size="small"
            className={ styles.actionButton }
            onClick={ handleShowMyMaps }
          >
            <ViewListIcon />
            <span><FormattedMessage id="mapbiomas.header.buttons.my_maps" /></span>
          </Button>
        </div>
        {/* <Tooltip title={ headerIsVisible ? formatMessage(`mapbiomas.header.tooltips.hide_filters`) : formatMessage(`mapbiomas.header.tooltips.show_filters`) }>
          <button
            id="data-toggle-button"
            className={ classnames(styles.toggleButton, {
              [styles.toggleButtonAlone]: !headerIsVisible
            }) }
            onClick={ toggleVisibility }
          >
            <FilterListIcon />
          </button>
        </Tooltip>
        <a
          className={ classnames(styles.logoBox, {
            [styles.logoBoxHidden]: headerIsVisible
          }) }
          href="https://mapbiomas.org/"
        >
          <img src={ logo } alt="Logo MapBiomas" />
        </a> */}
      </div>
    );
  };

  const renderFireModeContent = () => {
    return (
      <div className={ styles.fireModeContentWrapper } style={{ height: windowSize.height - 116 }}>
        <div className={ styles.fireModeContent }>
          <div className={ styles.fireModeContentBox }>
            <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.header.fire_module_content.default.title" /></h2>
            <div
              className={ styles.fireModeContentBoxTextWrapper }
              dangerouslySetInnerHTML={{
                __html: formatMessage('mapbiomas.header.fire_module_content.default.body')
              }}
            />
            <div className={ styles.fireModeContentBoxActions }>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={ handleActiveModuleChange.bind(this, 'fire') }
                className={ styles.fireModeContentBoxButton }
              >
                <FormattedMessage id="mapbiomas.statistics.view_data" />
              </Button>
            </div>
          </div>
          <div className={ styles.fireModeContentBox }>
            <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.header.fire_module_content.monitor.title" /></h2>
            <div
              className={ styles.fireModeContentBoxTextWrapper }
              dangerouslySetInnerHTML={{
                __html: formatMessage('mapbiomas.header.fire_module_content.monitor.body')
              }}
            />
            <div className={ styles.fireModeContentBoxActions }>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={ showFireIframeMode }
                className={ styles.fireModeContentBoxButton }
              >
                <FormattedMessage id="mapbiomas.statistics.view_data" />
              </Button>
            </div>
          </div>
          <div className={ styles.fireModeContentBoxExtra }>
            <div
              className={ styles.fireModeContentBoxTextWrapper }
              dangerouslySetInnerHTML={{
                __html: formatMessage('mapbiomas.header.fire_module_content.extra.body')
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderInfrastructureModeContent = () => {
    return (
      <div className={ styles.fireModeContentWrapper } style={{ height: windowSize.height - 116 }}>
        <div className={ styles.fireModeContent }>
          <div className={ styles.fireModeContentBoxExtra }>
            <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.header.infrastructure_module_content.default.title" /></h2>
            <div
              className={ styles.fireModeContentBoxTextWrapper }
              dangerouslySetInnerHTML={{
                __html: formatMessage('mapbiomas.header.infrastructure_module_content.default.body')
              }}
            />
          </div>
          <div className={ styles.fireModeContentBox }>
            <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.header.infrastructure_module_content.default.title" /></h2>
            <div
              className={ styles.fireModeContentBoxTextWrapper }
              dangerouslySetInnerHTML={{
                __html: formatMessage('mapbiomas.header.infrastructure_module_content.collection_6.body')
              }}
            />
            <div className={ styles.fireModeContentBoxActions }>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={ handleActiveModuleChange.bind(this, 'infrastructure') }
                className={ styles.infrastructureModeContentBoxButton }
              >
                <FormattedMessage id="mapbiomas.statistics.view_data" />
              </Button>
            </div>
          </div>
          <div className={ styles.fireModeContentBox }>
            <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.header.infrastructure_module_content.landing_fields.title" /></h2>
            <div
              className={ styles.fireModeContentBoxTextWrapper }
              dangerouslySetInnerHTML={{
                __html: formatMessage('mapbiomas.header.infrastructure_module_content.landing_fields.body')
              }}
            />
            <div className={ styles.fireModeContentBoxActions }>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={ showInfrastructureIframeMode }
                className={ styles.infrastructureModeContentBoxButton }
              >
                <FormattedMessage id="mapbiomas.statistics.view_data" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderIrrigationModeContent = () => {
    return (
      <div className={ styles.fireModeContentWrapper } style={{ height: windowSize.height - 116 }}>
        <div className={ styles.fireModeContent }>
          <div className={ styles.fireModeContentBox }>
            <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.header.irrigation_module_content.default.title" /></h2>
            <div
              className={ styles.fireModeContentBoxTextWrapper }
              dangerouslySetInnerHTML={{
                __html: formatMessage('mapbiomas.header.irrigation_module_content.default.body')
              }}
            />
            <div className={ styles.fireModeContentBoxActions }>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={ handleActiveModuleChange.bind(this, 'irrigation') }
                className={ styles.irrigationModeContentBoxButton }
              >
                <FormattedMessage id="mapbiomas.statistics.view_data" />
              </Button>
            </div>
          </div>
          <div className={ styles.fireModeContentBox }>
            <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.header.irrigation_module_content.pivots.title" /></h2>
            <div
              className={ styles.fireModeContentBoxTextWrapper }
              dangerouslySetInnerHTML={{
                __html: formatMessage('mapbiomas.header.irrigation_module_content.pivots.body')
              }}
            />
            <div className={ styles.fireModeContentBoxActions }>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={ showIrrigationIframeMode }
                className={ styles.irrigationModeContentBoxButton }
              >
                <FormattedMessage id="mapbiomas.statistics.view_data" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDeforestationModeContent = () => {
    return (
      <div className={ styles.fireModeContentWrapper } style={{ height: windowSize.height - 116 }}>
        <div className={ styles.fireModeContent }>
          <div className={ styles.fireModeContentBox }>
            <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.header.deforestation_module_content.default.title" /></h2>
            <div
              className={ styles.fireModeContentBoxTextWrapper }
              dangerouslySetInnerHTML={{
                __html: formatMessage('mapbiomas.header.deforestation_module_content.default.body')
              }}
            />
            <div className={ styles.fireModeContentBoxActions }>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={ handleActiveModuleChange.bind(this, 'deforestation') }
                className={ styles.deforestationModeContentBoxButton }
              >
                <FormattedMessage id="mapbiomas.statistics.view_data" />
              </Button>
            </div>
          </div>
          <div className={ styles.fireModeContentBox }>
            <h2 className={ styles.fireModeContentBoxTitle }><FormattedMessage id="mapbiomas.header.deforestation_module_content.alerts.title" /></h2>
            <div
              className={ styles.fireModeContentBoxTextWrapper }
              dangerouslySetInnerHTML={{
                __html: formatMessage('mapbiomas.header.deforestation_module_content.alerts.body')
              }}
            />
            <div className={ styles.fireModeContentBoxActions }>
              <Button
                variant="contained"
                color="primary"
                size="small"
                component="a"
                target="_blank"
                href="https://plataforma.alerta.mapbiomas.org/"
                className={ styles.deforestationModeContentBoxButton }
              >
                <FormattedMessage id="mapbiomas.statistics.view_data" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderWaterModuleIcon = () => {
    return (
      <CustomTooltip
        arrow
        title={ locale === 'pt-BR' ? 'Água' : 'Water' }
        placement="right"
      >
        <li
          className={ classnames(styles.menuBarListItem, styles.menuBarListItemWater, {
            [styles['menuBarListItemWaterIsActive']]: isWaterIframe
          }) }
          onClick={ handleWaterClick }
          style={ !isWaterIframe ? {} : { backgroundColor: 'rgba(24, 103, 156, .1)' } }
        >
          <span
            className={ styles.menuBarListItemLine }
          />
          { !_.isEmpty(modulesList) &&
            <ModuleIconComponent
              moduleKey="water"
            />
          }
          {/* <span className={ styles.menuBarListItemNewTag }>new</span> */}
        </li>
      </CustomTooltip>
    );
  };

  const renderLogo = () => {
    let logoImage = logo;

    if (_.get(activeModuleData, 'key') === 'fire' || showFireModeContent) {
      logoImage = logoFire;
    } else if (_.get(activeModuleData, 'key') === 'sentinel' && locale === 'pt-BR') {
      logoImage = logoSentinelPT;
    } else if (_.get(activeModuleData, 'key') === 'sentinel' && locale === 'en') {
      logoImage = logoSentinelEN;
    }

    return (
      <img
        id="logo"
        src={ logoImage }
        alt="Logo MapBiomas"
        className={ classnames({
          [styles.headerImageLogoSentinel]: _.get(activeModuleData, 'key') === 'sentinel'
        }) }
      />
    );
  };

  return (
    <div
      className={ classnames(styles.wrapper, {
        [styles.wrapperHidden]: !headerIsVisible,
        [styles.wrapperIframeMode]: iframeMode &&
          !showFireModeContent &&
          !showInfrastructureModeContent &&
          !showIrrigationModeContent &&
          !showDeforestationModeContent
      }) }
      style={{ height }}
    >
      <header
        className={ classnames(styles.headerWrapper, {
          [styles.headerWrapperHidden]: !headerIsVisible
        }) }
        style={{ height }}
      >
        <div className={ styles.menuBar }>
          <ul id="modules" className={ styles.menuBarList }>
            { _.map(_.sortBy(modulesList, 'displayOrder'), (item) => {
              const stringList = _.get(item, 'i18nStrings');
              const selectedString = _.find(stringList, { language: locale });
              const label = _.get(selectedString, 'stringValue');

              const isActive = !showFireModeContent && !showInfrastructureModeContent && !showIrrigationModeContent && !iframeMode && _.get(activeModuleData, 'key') === item.key ||
                (item.key === 'fire' && (showFireModeContent || isFireIframe)) ||
                (item.key === 'infrastructure' && (showInfrastructureModeContent || isInfrastructureIframe)) ||
                (item.key === 'irrigation' && (showIrrigationModeContent || isIrrigationIframe)) ||
                (item.key === 'deforestation' && showDeforestationModeContent);

              const rgbColor = hexToRgb(item.primaryColor);

              return (
                <Fragment key={ `nav-list-item-${ item.key }` }>
                  <CustomTooltip
                    arrow
                    title={ label }
                    placement="right"
                  >
                    <li
                      className={ classnames(styles.menuBarListItem, [styles[getModuleItemClassName(item.key)]], {
                        [styles[`${ getModuleItemClassName(item.key) }IsActive`]]: isActive
                      }) }
                      onClick={ !_.includes(['fire', 'infrastructure', 'irrigation', 'deforestation'], item.key) ?
                        handleActiveModuleChange.bind(this, mapNewModuleListKeyToOldKey(item.key)) :
                        handleCustomModeClick.bind(this, item.key)
                      }
                      style={ !isActive ? {} : { backgroundColor: `rgba(${ rgbColor.r }, ${ rgbColor.g }, ${ rgbColor.b }, .1)` } }
                    >
                      <span
                        className={ styles.menuBarListItemLine }
                      />
                      <ModuleIconComponent
                        moduleKey={ item.key }
                      />
                      { _.includes(NEW_MODULE_KEYS, item.key) &&
                        <span className={ styles.menuBarListItemNewTag }>new</span>
                      }
                      { _.includes(BETA_MODULE_KEYS, item.key) &&
                        <span className={ styles.menuBarListItemBetaTag }>beta</span>
                      }
                    </li>
                  </CustomTooltip>
                </Fragment>
              );
            }) }
          </ul>
          <div className={ styles.menuBarBottomContent }>
            { !iframeMode &&
              <CustomTooltip arrow title={ formatMessage('mapbiomas.sidebar.account.title') } placement="right">
                <button className={ styles.menuButton } onClick={ toggleAccountMenu }>
                  <AccountCircleIcon />
                </button>
              </CustomTooltip>
            }
            <CustomTooltip arrow title={ formatMessage('mapbiomas.sidebar.institutional.title') } placement="right">
              <button className={ styles.menuButton } onClick={ toggleMenu }>
                <MenuIcon />
              </button>
            </CustomTooltip>
            { !iframeMode &&
              <button id="translation-button" className={ styles.translateButton } onClick={ handleTranslateBoxOpen }>
                <TranslateIcon />
                <span>{ mapLocaleKeyToAbbreviation[locale] }</span>
                <ExpandMoreIcon />
              </button>
            }
            <TranslateOptionsBox
              anchorEl={ translateBoxAnchorEl }
              onClose={ setTranslateBoxAnchorEl.bind(this, null) }
            />
          </div>
        </div>
        { !showFireModeContent &&
          !showInfrastructureModeContent &&
          !showIrrigationModeContent &&
          !showDeforestationModeContent &&
          renderMenuContent()
        }
        { showFireModeContent && renderFireModeContent() }
        { showInfrastructureModeContent && renderInfrastructureModeContent() }
        { showIrrigationModeContent && renderIrrigationModeContent() }
        { showDeforestationModeContent && renderDeforestationModeContent() }
        <div
          className={ classnames(styles.linksContent, {
            [styles.linksContentIsVisible]: menuIsVisible,
            [styles.linksContentIframeMode]: iframeMode,
          }) }
        >
          <InstitutionalMenu iframeMode={ iframeMode } />
        </div>
        <div
          className={ classnames(styles.linksContent, {
            [styles.linksContentIsVisible]: !!accountMenuIsVisible,
            [styles.linksContentIframeMode]: iframeMode,
          }) }
        >
          <AccountMenu
            iframeMode={ iframeMode }
            myMapsMode={ accountMenuIsVisible === 'myMaps' }
            newMapMode={ accountMenuIsVisible === 'newMap' }
            onHideMenu={ closeAccountMenu }
          />
        </div>
      </header>
      <div
        className={ classnames(styles.logoBox, {
          [styles.logoBoxCollapsed]: !headerIsVisible
        }) }
      >
        <a
          className={ styles.mainLogo }
          href="https://chile.mapbiomas.org/"
        >
          { renderLogo() }
          { ['coverage','deforestation','regeneration','irrigation','mining','temporal_analysis','coverage_quality', 'infrastructure'].includes(_.get(activeModuleData, 'key')) && <span className={ styles.versionTag }>v. 1.0</span> }
          { ['fire','quality_of_pasture_data'].includes(_.get(activeModuleData, 'key')) && <span className={ styles.versionTag }>v. 2.0</span> }
          { false && _.includes(['infrastructure'], _.get(activeModuleData, 'key')) &&
            <span className={ styles.newTag }>New</span>
          }
          { _.includes(BETA_MODULE_KEYS, _.get(activeModuleData, 'key')) &&
            <span className={ styles.betaTag }>Beta</span>
          }
        </a>
        <button
          id="toggle-header-button"
          className={ classnames(styles.toggleButton, {
            [styles.toggleButtonExpand]: !headerIsVisible
          }) }
          onClick={ toggleVisibility }
        >
          <ChevronLeftIcon />
        </button>
      </div>
    </div>
  );
}

export default withRouter(Header);
