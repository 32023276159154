import React from 'react';
import { FormattedMessage } from 'react-intl';

import useWindowSize from '../../../../../../hooks/useWindowSize';

import styles from './InstitutionalMenu.module.scss';

const LOGO_HEIGHT = 70;
const TITLE_HEIGHT = 50;
const FOOTER_HEIGHT = 30;

export default function InstitutionalMenu({
  iframeMode,
}) {
  const windowSize = useWindowSize();
  const contentHeight = windowSize.height - (!iframeMode ? LOGO_HEIGHT : 0) - TITLE_HEIGHT - FOOTER_HEIGHT;

  return (
    <div>
      <h2 className={ styles.title }><FormattedMessage id="mapbiomas.sidebar.institutional.title" /></h2>
      <div className={ styles.navListWrapper } style={{ height: contentHeight }}>
        <ul className={ styles.navList }>
          <li>
            <span><FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.title" /></span>
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/proyecto/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.project" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/productos/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.products" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/quienes-somos/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.about" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/equipo/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.team" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/otras-iniciativas/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.other_initiatives" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/terminos-de-uso/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.terms_of_use" />
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span><FormattedMessage id="mapbiomas.sidebar.institutional.menu.maps_and_data.title" /></span>
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/descargas/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.maps_and_data.links.downloads" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/terminos-de-uso/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.maps_and_data.links.terms_of_use" />
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span><FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.title" /></span>
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/descripcion-general-de-la-metodologia/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.methodology_overview" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/atbd-entienda-cada-etapa/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.atdb" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/herramientas/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.tools" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/glosario/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.glossary" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/terminos-de-uso/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.terms_of_use" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/preguntas-frecuentes/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.qa" />
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span><FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.title" /></span>
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/lanzamientos/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.releases" />
                </a>
              </li>
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="https://colombia.mapbiomas.org/noticias/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.news" />
                </a>
              </li> */}
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="https://uruguay.mapbiomas.org/articulos-de-la-iniciativa-mapbiomas-uruguay/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.articles" />
                </a>
              </li> */}
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="https://colombia.mapbiomas.org/premio-mapbiomas/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.award" />
                </a>
              </li> */}
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/videos/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.videos" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/terminos-de-uso/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.terms_of_use" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://chile.mapbiomas.org/contacto/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.contact" />
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}
