export default [
  {
    index: 0,
    key: "country",
    labelKey: "country",
    color: "#FF0000",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-chile:country"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Biblioteca del Congreso Nacional (Modificado por equipo)' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/chile/collection_1/download/layers/nivel-politico-1.zip'
  },
  {
    index: 1,
    key: "region",
    labelKey: "region",
    color: "#FFA500",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-chile:region"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Biblioteca del Congreso Nacional (Modificado por equipo)' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/chile/collection_1/download/layers/nivel-politico-2.zip'
  },
  {
    index: 2,
    key: "province",
    labelKey: "province",
    color: "#FF00FF",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-chile:province"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Biblioteca del Congreso Nacional (Modificado por equipo)' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/chile/collection_1/download/layers/nivel-politico-3.zip'
  },
  {
    index: 3,
    key: "municipality",
    labelKey: "municipality",
    color: "#FFFF00",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-chile:municipality"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Biblioteca del Congreso Nacional (Modificado por equipo)' },
      { labelKey: 'year', label: 'Ano', value: '2023' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/chile/collection_1/download/layers/nivel-politico-4.zip'
  },
  {
    index: 4,
    key: "basins",
    labelKey: "basins",
    color: "#FFC0CB",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-chile:basins"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Dirección General de Aguas (Modificado por equipo)' },
      { labelKey: 'year', label: 'Ano', value: '2024' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/chile/collection_1/download/layers/cuencas.zip'
  },
  {
    index: 5,
    key: "sub_basins",
    labelKey: "sub_basins",
    color: "#800080",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-chile:sub_basins"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Dirección General de Aguas (Modificado por equipo)' },
      { labelKey: 'year', label: 'Ano', value: '2024' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/chile/collection_1/download/layers/sub-cuencas.zip'
  },
  {
    index: 6,
    key: "ecoregions",
    labelKey: "ecoregions",
    color: "#00FF00",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-chile:ecoregions"
    },
    descriptionData: [
      { labelKey: 'source', label: 'Fonte', value: 'Equipo MapBiomas' },
      { labelKey: 'year', label: 'Ano', value: '2024' },
    ],
    downloadUrl: 'https://storage.googleapis.com/mapbiomas-public/initiatives/chile/collection_1/download/layers/ecoregiones.zip'
  }
];
